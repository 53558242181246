<template>
	<edit-template class="main" @confirm="submitForm('numberValidateForm')" @cancel="$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        $router.back()
      })" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div class="title">
      <el-form ref="numberValidateForm" :model="numberValidateForm" class="demo-ruleForm" label-position="left" label-width="100rem">
        <el-form-item :rules="[{ required: true, message: '请选择适用校区'}]" label="适用校区" prop="school_id">
          <el-select v-model="numberValidateForm.school_id" placeholder="请选择适用校区">
            <el-option v-for="(i,n) in school" :key="i.id" :label="i.school_name" :value="i.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请选择适用年级'}]" label="适用年级" prop="grade_id">
          <el-checkbox-group v-model="numberValidateForm.grade_id">
            <el-checkbox v-for="(i,n) in grade" :key="i.id" :label="i.id" :value="i.id" name="type">{{ i.name }}</el-checkbox>
          </el-checkbox-group>

        </el-form-item>

        <div style="font-size: 16rem;font-weight: 600;color: #333333;margin-top: 24rem;margin-bottom: 16rem">可辅导年级</div>

        <div v-for="(i,n) in numberValidateForm.time_list" class="body">
          <div style="width: 100%;background: #F6F9FF;border-radius: 4rem 4rem 4rem 4rem;display: flex;align-items: center;padding: 12rem 24rem;box-sizing: border-box">
            <span>时间段名称</span>
            <el-input v-model="i.name" maxlength="20" placeholder="请输入时间段名称"></el-input>
            <span>选择时间</span>
            <el-time-select v-model="i.start_time" :picker-options="{start: '00:00',step: '00:05', end: '23:59'}" placeholder="选择时间"></el-time-select>
            <span>至</span>
            <el-time-select v-model="i.end_time" :disabled="!(i.start_time)" :picker-options="{start: '00:00',step: '00:05', end: '23:59'}" placeholder="选择时间" @change="onChaneTime(n)"></el-time-select>
            <span>周期</span>
            <el-select v-model="i.week_range" multiple="" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <div style="margin-left: 40rem">
              <el-button style="background: #326FFF;border: none" type="primary" @click="Add">添加</el-button>
              <el-button v-if="numberValidateForm.time_list.length>1" style="border: 1rem solid #326FFF;background: #F6F9FF" @click="onDelete(n)">删除</el-button>
            </div>
          </div>
        </div>

      </el-form>
    </div>


    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  name: "Edit",
  data() {
    return {
      value: '',
      school: [],
      grade: [],
      options: [{
        label: '每周一',
        value: '1'
      }, {
        label: '每周二',
        value: '2'
      }, {
        label: '每周三',
        value: '3'
      }, {
        label: '每周四',
        value: '4'
      }, {
        label: '每周五',
        value: '5'
      }, {
        label: '每周六',
        value: '6'
      }, {
        label: '每周日',
        value: '0'
      },],
      numberValidateForm: {
        grade_id: [],
        time_list: [{
          name: '',
          start_time: '',
          end_time: '',
          week_range: '',
        }]
      },
    }
  },
  methods: {
    Add() {
      if (this.numberValidateForm.time_list.length >= 50) {
        this.$message.error('最多添加50个时间段')
        return
      }
      this.numberValidateForm.time_list.push({
        name: '',
        start_time: '',
        end_time: '',
        week_range: '',
      })
    },
    onDelete(n) {
      this.numberValidateForm.time_list.splice(n, 1)
    },
    onChaneTime(n) {
      if (this.numberValidateForm.time_list[n].time1 <= this.numberValidateForm.time_list[n].time) {
        this.numberValidateForm.time_list[n].time1 = ''
        this.$message.error('结束辅导时间需在开始辅导时间之后')
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.numberValidateForm))
          data.grade_id = data.grade_id.join(',')
          for (let i = this.numberValidateForm.time_list.length - 1; i >= 0; i--) {
            if (this.numberValidateForm.time_list[i].name === '') return this.$message.error('请填写时间段名称')
            if (this.numberValidateForm.time_list[i].start_time === '') return this.$message.error('请选择开始时间')
            if (this.numberValidateForm.time_list[i].end_time === '') return this.$message.error('请选择结束时间')
            if (this.numberValidateForm.time_list[i].week_range.length === 0) {
              return this.$message.error('请选择周期')
            } else {
              data.time_list[i].week_range = this.numberValidateForm.time_list[i].week_range.join(',')
            }
          }
          this.$_axios2.post('api/coach-task/add-cycle', data, {logic: 1}).then(res => {
            this.$message.success('新增成功')
            this.$router.back()
          })
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },

  created() {
    this.$_axios.get('site/school').then(res => {
      this.school = res.data.data
      this.$forceUpdate()
    })
    this.$_axios2.get('api/common/grade').then(res => {
      this.grade = res.data.data;
      this.$forceUpdate()
    })
  }
}
</script>

<style lang="scss" scoped>
.main {
  box-sizing: border-box;
  width: 100%;
  min-height: 240rem;
  padding: 24rem;
  border-radius: 8rem 8rem 8rem 8rem;
  //background: #F9FAFD !important;

}

.body {
  margin-bottom: 16rem;

  span {
    font-size: 16rem;
    font-weight: 400;
    margin: 0 24rem;
    color: #666666;
  }
}

.left {
  font-size: 16rem;
  line-height: 40rem;
  width: 80rem;
  height: 20rem;
  color: #666666;
}

.right {
  font-size: 16rem;
  font-weight: 600;
  color: #1E3AA2;
}

::v-deep .el-input {
  flex: 1 !important;
}

::v-deep .el-input__inner {
  flex: 1 !important;
}

::v-deep .el-select__tags {
  flex: 1 !important;
}

::v-deep .el-form-item__label {
  font-size: 16rem;
  font-weight: 600;
  color: #333333;
}

</style>
